import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import { pc, sp, tab } from '../styles/media';
import { Helmet } from "react-helmet"
import { Link } from "gatsby"


// 参考) https://www.cantorsparadise.com/learn-anything-faster-by-using-the-feynman-technique-6565a9f7eda7
const Wrapper = styled.div`
  width: 100%;
  max-width: 680px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`
const Title01 = styled.h2`
  ${sp`
  font-size: 22px;
  line-height: 38px;
  `}
  ${pc`
  font-size: 24px;
  line-height: 42px;
  `}
  border-bottom: solid 1px #cbd0d5;
`
const ImageArea = styled.div`
  width: 100%;
  ${sp`
  margin-top: 28px;
  `}
  ${pc`
  margin-top: 56px;
  `}
`
const Body01 = styled.div`
  ${sp`
  margin-top: 1em;
  font-size: 18px;
  `}
  ${pc`
  margin-top: 2em;
  font-size: 18px;
  `}
  line-height: 32px;
`
const Title02 = styled.h2`
${sp`
font-size: 22px;
line-height: 38px;
`}
${pc`
font-size: 24px;
line-height: 42px;
`}
border-bottom: solid 1px #cbd0d5;
margin-top: 1em;
`
const Body02 = styled.div`
  font-size: 18px;
  margin-top: 2em;
  line-height: 32px;
`
const Title03 = styled.h2`
${sp`
font-size: 22px;
line-height: 38px;
`}
${pc`
font-size: 24px;
line-height: 42px;
`}
border-bottom: solid 1px #cbd0d5;
margin-top: 1em;
`
const Body03 = styled.div`
  font-size: 18px;
  margin-top: 2em;
  line-height: 32px;
  margin-bottom: 2em;
`
const BlogParagraph = styled.p`
`
const BlogLink = styled.a`
    color: #31aae2;
    text-decoration: underline;
    transition: color 0.3s;
    &:visited{
        color : rgb(89,201,171);
    }
    &:hover{
        color : #ff7043;
    }
`
const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between; 
    ${sp`
    font-size: 18px;
    `}
    ${pc`
    font-size: 18px;
    `} 
`
const PrevButton = styled.a`
    text-decoration: underline;
`
const NextButton = styled.a`
    text-decoration: underline;
`

function BlogBody({ body }) {
    // MD の [Google(リンク)](https://google.com) 記法を a タグへ変換する
    // [本文](リンク) を抽出
    const reTarget = /\[.+?\]\(.+?\)/g
    const targetArray = body.match(reTarget)

    // body 要素の再構築
    let bodyArray = body.split(reTarget)  // [xx](yy) 以外の部分
    let bodyContents = []
    if(targetArray!==null){  // match した場合
        const reLink = /\[(.+?)\]\((.+)\)/  // 抽出されている [] と () の内容を取得
        for(const i in targetArray){
            bodyContents.push(bodyArray.shift())  // マッチ部の前半部分の挿入
            let aElements = targetArray[i].match(reLink)  // 抽出されている [] と () の内容を取得
            bodyContents.push(<BlogLink href={aElements[2]} target="_blank">{aElements[1]}</BlogLink>)  // a タグへの変換
        }
        bodyContents.push(bodyArray.shift())  // マッチ部の後半部分の挿入
    }else{
        bodyContents = [body]  // マッチしない場合はそのまま
    }
    return (
        <BlogParagraph>
            {bodyContents}
        </BlogParagraph>
    )
}

export default function BlogSinglePage({ data }) {
    const blogObj = data.Data.getBlog
    const blogIndexes = data.Data.listBlogsSortByUpdatedAt  
    // 次へ前へ用の DESC の id 配列
    // {
    //   "data": {
    //     "Data": {
    //       "listBlogsSortByUpdatedAt": {
    //         "items": [
    //           {
    //             "id": "551be044-db74-47d3-99c8-a491b7000e5a"
    //           },
    //           {
    //             "id": "a3981024-8712-4013-b226-5f3ed9b5e576"
    //           },
    //         ]
    //       }
    //     }
    //   }
    // }
    let prevPath = null
    let nextPath = null
    blogIndexes?.items?.forEach((item, i) => {
      if(item.id === blogObj.id){
        prevPath = blogIndexes.items[i-1] ? "/blog/" + blogIndexes.items[i-1].id : null
        nextPath = blogIndexes.items[i+1] ? "/blog/" + blogIndexes.items[i+1].id : null
      }
    })
    console.log(prevPath)
    console.log(nextPath)
    
    const createImage = (index, imageProperty) => {
      if (imageProperty!==null){
        return (
          <ImageArea>
            <GatsbyImage image={getImage(blogObj.pathBlogImages[index])} />
          </ImageArea>
        )
      }
    }

    return (
      <Layout>
        <Helmet>
            <title>【ビオエスト株式会社】｜関西エリアの倉庫・工場・土地ブログ</title>
            <meta name="description" content="倉庫・工場・土地等の運用についてお悩みなら、【ビオエスト株式会社】にご相談ください。
    主に大阪・和歌山・奈良・兵庫エリアにて、賃貸・売買・管理・物件調査、査定を通じて適切な運用方法をご一緒に検討いたします。" />
        </Helmet>
        <Wrapper>
          <Title01>
            {
              blogObj?.title01?.split("\n").map((line) => {
                return (
                    <p>{line}</p>
                )
            })
            }
          </Title01>
          { createImage(0, blogObj.image01) }
          <Body01>
          {
              blogObj?.body01?.split("\n").map((line) => {
                return (
                    <BlogBody body={line}/>
                )
            })
            }
          </Body01>
          <Title02>
            {
              blogObj?.title02?.split("\n").map((line) => {
                return (
                    <p>{line}</p>
                )
            })
            }
          </Title02>
          { createImage(1, blogObj.image02) }
          <Body02>
          {
              blogObj?.body02?.split("\n").map((line) => {
                return (
                    <BlogBody body={line}/>
                )
            })
            }
          </Body02>
          <Title03>
            {
              blogObj?.title03?.split("\n").map((line) => {
                return (
                    <p>{line}</p>
                )
            })
            }
          </Title03>
          { createImage(2, blogObj.image03) }
          <Body03>
          {
              blogObj?.body03?.split("\n").map((line) => {
                return (
                    <BlogBody body={line}/>
                )
            })
            }
          </Body03>
          <ButtonWrapper>
            <PrevButton>
              { prevPath ? <Link to={prevPath}>
                  前の記事へ
                </Link> : null
              }
            </PrevButton>
            <NextButton>
              {
                nextPath ? <Link to={nextPath}>
                  次の記事へ
                </Link> : null
              }
            </NextButton>
          </ButtonWrapper>
        </Wrapper>
      </Layout>
    )
}

export const query = graphql`
query BlogSingleQuery($id: ID!){
    Data {
      getBlog(id: $id) {
        title01
        title02
        title03
        body01
        body02
        body03
        image01
        image02
        image03
        createdAt
        id
        pathBlogImages{
          childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }      
        }
      }
      listBlogsSortByUpdatedAt(
        filter: {_deleted: {ne: true}}
        sortDirection: DESC
        type: BLOG) {
        items {
          id
        }
      }
    }
  }
`
